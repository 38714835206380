@import '../utils.scss';

.notificationBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include breakpoint(1000px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .titlebox_bottom{
        padding-top: 10px;
        border-top: 2px solid $gray;
    }

    .title {
        display: flex;
        font-size: 20px;
        justify-content: center;
        text-align: center;
        padding-bottom: 10px;
        border-width: 0px;
        border-bottom: 2px solid $gray;
    }

    .title_bottom{
        display: flex;
        font-size: 20px;
        justify-content: center;
        text-align: center;
        padding-bottom: 10px;
        color: $base_dark;
    }

    &_settingsLeftBox{
        display: flex;
        flex-direction: column;
        background-color: transparent;
        width: 40%;
        border: solid 2px $gray;
        border-radius: 15px;
        position: relative;

        .alert_inline {
            align-self: center;
            justify-self: center;

            span {
                font-size: 12px;
            }
        }

        .no_alert_div {
            height: 16px;
        }


        @include breakpoint(1000px) {
            width: 90%;
            margin-top: 20px;
        }
        .radio_box {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
        .select_menu{
            display: flex;
            margin: 10px;
            justify-content: center;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 2px solid $gray;
        }

        .selectedUsers {
            margin: 10px 0px 10px 0px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            flex-wrap: wrap;
            align-items: stretch;

            &_box {
                display: flex;
                justify-content: center;
                gap: 10px;
                flex-wrap: wrap;
                min-height: 45px;

                button {
                    display: flex;
                    gap: 5px;
                    justify-content: baseline;
                    border: solid 2px $base_very_light ;
                    background-color: transparent;
                    border-radius: 5px;
                    padding: 10px;
                    font-size: 16px;
                    color: $base_dark;
                }

                button:hover {
                    border-color: $base_light;
                    cursor: pointer;
                }

                button:active {
                    border-color: $gray;
                }

                button:disabled {
                    border: 1px solid rgba($gray_ver_dark, 0.3);
                    background-color: transparent;
                    color: rgba($gray_ver_dark, 0.3);
                }
            }

        }
        .left_box_next_button{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding: 15px;
            margin-top: auto;

            button {
                background-color: $white;
                border-radius: 5px;
                text-align: center;
                cursor: pointer;
                transition-duration: 0.1s;
                outline: none;
                border-radius: 20px;
                padding: 12px 22px 12px 22px;
                margin-left: 10px;
            }

            button:active {
                background-color: $base_medium;
                transition-duration: 0.1s;
            }

            button:hover {
                background-color: $base_very_light;
                color: $white;
                transition-duration: 0.1s;
            }

            button:disabled {
                border: 1px solid rgba($gray_ver_dark, 0.3);
                background-color: transparent;
                color: rgba($gray_ver_dark, 0.3);
            }
        }

    }

    &_settingsRightBox{
        background-color: transparent;
        width: 58%;
        border: solid 2px $gray;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;

        @include breakpoint(1000px) {
            width: 90%;
        }

        .alert_inline {
            align-self: center;
            justify-self: center;
            span{
                font-size: 12px;
            }
        }
        .no_alert_div {
            height: 16px;
        }

        .communications_settings {
            padding: 5px;

            &_checkBox_pane {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
            }

        }

        .notification_body {
            display: flex;
            flex-direction: column;
            padding: 15px;

            &_alert {
                margin: 7px 0px 7px 10px;
                font-size: 12px;
                color: $notify_red;
            }
            &_no_alert {
                height: 10px;
                width: 100%;
            }

            &_title {
                width: 100%;
            }

            &_title_error {
                width: 100%;
                border-color: $notify_red;
            }

            &_content {
                width: 100%!important;
            }

            &_content_error {
                width: 100%;
                border-color: $notify_red;
            }

        }
        .button_box{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding: 15px;

            button {
                background-color: $white;
                border-radius: 5px;
                text-align: center;
                cursor: pointer;
                transition-duration: 0.1s;
                outline: none;
                border: 1px solid $gray_medium;
                border-radius: 20px;
                padding: 12px 22px 12px 22px;
                margin-left: 10px;
                color: $base_dark;
            }

            button:active {
                background-color: $base_medium;
                transition-duration: 0.1s;
            }

            button:hover {
                background-color: $base_very_light;
                color: $white;
                transition-duration: 0.1s;
            }

            button:disabled {
                border: 1px solid rgba($gray_ver_dark, 0.3);
                background-color: transparent;
                color: rgba($gray_ver_dark, 0.3);
                cursor: default;
            }

        }
    }
}

.greyBox {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    z-index: 1;
    position: absolute;
    background-color: rgba($gray, 0.5);
    transition-duration: 0.2s;
}


.highlighted {
    border: 2px dashed $base_light!important;
    border-color: $base_very_light!important;
    transition-duration: 0.2s;
}

.deactivated {
    border-color: $gray;
}

.doneStep {
    border-color: $notify_green;
}

.highlightedButton {
    background-color: $base_medium!important;
    border: none!important;
    color: white!important;
}

.highlightedButton:hover {
    background-color: $base_very_light!important;
    color: white;
}

.summaryBox {
    width: 100%;
    border: solid 2px $gray;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    align-items: center;
    margin-top: 20px;
    transition-duration: 0.2s;

    span{
        text-align: center;
        padding: 20px;
    }

    .button_box {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 15px;
        width: 56.041%;

        button {
            background-color: $white;
            border-radius: 5px;
            text-align: center;
            cursor: pointer;
            transition-duration: 0.1s;
            outline: none;
            border: 1px solid $gray_medium;
            border-radius: 20px;
            padding: 12px 22px 12px 22px;
            margin-left: 10px;
            color: $base_dark;
        }

        button:active {
            background-color: $base_medium;
            transition-duration: 0.1s;
        }

        button:hover {
            background-color: $base_very_light;
            color: $white !important;
            transition-duration: 0.1s;
        }

        button:disabled {
            border: 1px solid rgba($gray_ver_dark, 0.3);
            background-color: transparent;
            color: rgba($gray_ver_dark, 0.3);
        }
    }
}


