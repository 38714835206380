@import '../utils.scss';

.main {
    .container_main {
        &_pagination{
            width: 100%;
            display: flex;
            align-self: center;
            align-items: center;
            margin-bottom: 30px;
            margin-top: 20px;

            .paginationInfo {
                flex-basis: 30%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                transition-duration: 0.3s;
                gap: 5px;

                @include breakpoint(800px) {
                    flex-basis: 100%;
                    transition-duration: 0.3s;
                    justify-content: space-evenly;
                }

                div {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    span{
                        color: $base_dark;
                    }
                }
            }

            hr {
                flex-basis: 30%;
                border: none;
                border-top: 2px solid $gray;

                @include breakpoint(800px) {
                    flex-basis: 0;
                }
            }

            button {
                display: flex;
                border-style: none;
                background-color: $gray;
                padding: 12px;
                border-radius: 25px;
                align-items: center;
                justify-content: center;
                transition-duration: 0.2s;
                cursor: pointer;
            }
            button:hover {
                background-color: $base_very_light;
                transition-duration: 0.2s;
            }
            button:active {
                background-color: $base_light;
                transition-duration: 0.2s;
            }
        }
    }
}