@import "../utils.scss";

.buttonComponent {
    border-radius: 10px;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    background-color: $base_dark;
    color: $white;
    cursor: pointer;
    transition-duration: 0.1s;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-basis: 50%;
}

.buttonComponent:active {
    background-color: $base_medium;
    transition-duration: 0.1s;
}

.dialogNextPageButton {
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #2952CC;
    color: $white;
    border-radius: 10px;
    margin-left: 5px;
    transition-duration: 0.1s;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.dialogNextPageButton:active {
    background-color: $base_medium;
    transition-duration: 0.1s;
}

.clearButton {
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    background-color: rgba(255, 255, 255, 0);
    color: $black;
    cursor: pointer;
    transition-duration: 0.2s;
    gap: 10px;

    &:hover {
        background-color: $notify_red;
        transition-duration: 0.2s;
    }

    span {
        background-color: $white;
        border-radius: 50px;
        display: flex;
    }

    .checked {
        color: $notify_green
    }

    .notChecked {
        color: $notify_red
    }
}

.pictureButton {
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: $black;
    cursor: pointer;
    transition-duration: 0.2s;
    gap: 10px;
    background-color: $gray;
    user-select: text;

    &:hover {
        background-color: $base_dark;
        transition-duration: 0.2s;
        color: white;

        &[disabled]{
            background-color: $gray;
            color: $black;
            cursor: auto;
        }
    }
}
