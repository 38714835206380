@import "../utils.scss";

.container {
    &_input_wrapper {
        background-color: $white;
        margin-bottom: 5px;
        margin-top: 5px;
        border-radius: 10px;
        display: flex;
    }

    &_input {
        flex-basis: calc(100% - 40px);
        border-radius: 10px;
        border-width: 0;
        font-size: 16px;
        padding: 15px 20px 15px 20px;
    }

    &_password {
        flex-basis: calc(90% - 40px);
    }

    &_icon {
        flex-basis: calc(10%);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        background-color: $white;
        -webkit-tap-highlight-color: rgba(255,255,255,0);
    }

    &_input:active, &_input:focus {
        outline: none;
    }
}
