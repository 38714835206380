@import '../utils.scss';

.document-detail{
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    justify-content: space-between;

    &__top{
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
    }

    &__bottom{
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0.5rem;
    }

    &__column{
        display: flex;
        flex-direction: column;
        margin-right: 2rem;
    }

    span{
        font-size: 15px;
        line-height: 20px;
    }

    &__title{
        margin: 0px;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        padding-bottom: 4px;
        border-bottom: 2px solid rgb(255, 255, 255);
    }
}

.user-detail-row {
    transition-duration: 0.1s;

    &:hover {
        background-color: $not_verified;
        transition-duration: 0.1s;
    }
}

.document-detail-dialog{
    display: flex;
    padding: 0.5rem;
}

.box-measurement{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.btn-pills{
    display: flex;
    justify-content: flex-end;
}

.box-pills{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1rem;
    gap: 5px;

    &__measurement{
        width: auto;
        height: auto;
        margin: 0 5px 5px 0;
        padding: 5px;
    }

    &__notification{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 8px;
    }

    &__measurement-list{
        padding: 0.3rem;
        height: 1.4rem;
    }
}

.history-reason{
    font-size: 14px;
    color: #474d66;
    padding: 10px;
}