@import "../utils.scss";

.main {
    .file_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border: 2px dashed $base_light;
        flex-basis: 50%;
        border-radius: 15px;
        min-height: 350px;

        span {
            text-align: center;
        }

        input[type="file"] {
            display: none;
        }

        label {
            pointer-events: all;
            margin: unset;
        }

        p {
            margin: unset;
            margin-top: 10px;
            font-size: 15px;
        }

        #checkbox_container {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .custom-file-upload {
            display: flex;
            align-items: center;
            padding: 12px;
            cursor: pointer;
            background-color: $base_medium;
            color: $white;
            border-radius: 10px;
            border-width: 0;
            outline: none;
        }
    }

    .add_invoice {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 10px;

        @include breakpoint(600px) {
            flex-direction: column;
        }

        .row {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            width: 100%;
        }

        .current {
            border: 2px dashed $base_light;
        }

        .done {
            border: 2px solid $notify_green;
        }
    }

    .file_box {
        * { pointer-events: none; }
        button { pointer-events: auto !important; }

        @include breakpoint(600px) {
            min-height: 200px;
        }
    }

    .file_details {
        flex-basis: calc(50% - 2 * 30px);
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: hidden;
        position: relative;
        border-radius: 15px;

        input[type="text"] {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .clearButton:hover {
            background-color: $base_very_light;
            color: $white;
        }

        .send_button {
            flex-basis: unset;
            margin-top: auto;

            svg {
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
            }
        }

        .disabled {
            top: -5px;
            left: -5px;
            padding: 20px;
            width: calc(100% - 2 * 10px);
            height: 100%;
            position: absolute;
            z-index: 10;
            background-color: rgba($gray, 0.5);
            transition-duration: 0.2s;
            overflow: hidden;
        }

        .data_grid {
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: 20px;
            row-gap: 5px;
            align-items: center;

            #spacer_row {
                grid-area: auto / 1 / auto / 3;
                margin: 20px 0 10px 0;
            }

            .indent {
                text-indent: 1em;
            }

            .inLine {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
    }

    #invoice_details {
        margin: 50px 0 10px 0;
    }

    .invoice_details {
        display: flex;
        gap: 10px;
        padding: 10px;
        justify-content: space-around;

        &_column {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 20px;
            border-right: 2px dashed $gray_medium;

            div {
                flex-basis: unset !important;
            }

            @include breakpoint(600px) {
                border-right: none;
            }
        }

        div {
            display: flex;
            flex-direction: column;
            flex-basis: 40%;

            span:first-of-type {
                font-size: 1.2em;
                margin-bottom: 10px;
            }

            span {
                line-height: 1.3em;
            }

            img {
                max-width: 100%;
            }
        }

        @include breakpoint(600px) {
            flex-direction: column;
        }
    }
}

.confirm_payment_btns {
    display: flex;
    justify-content: flex-end;
}

.confirm_payment_btn {
    margin-right: 10px;
    width: 20%;
}

.payment_done_btn {
    background-color: $notify_green !important;
    cursor: default !important;
    width: 110px;

    span, svg {
        color: $white;
    }
}

.blueButton {
    background-color: $base_medium !important;
    width: 110px;

    &:hover {
        background-color: $base_dark !important;
    }

    span, svg {
        color: $white;
    }
}

.payment_status_container {
    display: flex;
    align-items: center;
    background-color: $white;
    justify-content: space-evenly;
    padding: 5px;
    border-radius: 10px;
    width: 100px;
    color: $black;
}

.payment_process_popup {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 50px 0;
}
.documents {
    &__UserDialog {
        font-size: 0.95em;

        .data_grid {
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: 20px;
            row-gap: 5px;
            align-items: center;

            #spacer_row {
                grid-area: auto / 1 / auto / 3;
                margin: 20px 0 10px 0;
            }
        }

        &__Row {
            cursor: pointer !important;
        }
    }
}


.input{
    &_valid{
        border: 2px solid $notify_green !important;
    }
    &_invalid{
        border: 2px solid red !important;
    }
    &_warrning{
        font-size: 0.8rem ;
        color: red !important;
    }
}

.star{
    color: red;
}

.tittle_margin{
    margin: 0;
}