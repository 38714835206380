@import "../utils.scss";

.footer {
    width: calc(100% - 180px);
    padding: 22px 0 22px 180px;
    margin-top: auto;
    border-top: 2px solid $base_light;
    background-color: $glass;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 0.9em;

    @include breakpoint(800px) {
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    @include breakpoint(600px) {
        padding: 22px 0 22px 0;
        width: 100%;
    }

    .href_group {
        margin-right: 50px;
        display: flex;
        gap: 40px;
        text-align: center;

        span{
            color: $base_dark;
        }
        span:hover{
            font-weight: 900;
            color: $base_light;
        }

        @include breakpoint(800px) {
            margin-right: 0;
        }
        a {
            color: black;
            cursor: pointer;
        }
    }

    .company_group {
        display: flex;
        align-items: center;
        gap: 20px;
        color: $base_dark;

        img {
            max-height: 22px;
        }
    }
}
