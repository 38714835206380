@import '../utils.scss';


.report{
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-top: 50px;
    border: 2px solid $gray;
    border-radius: 15px;
    padding: 20px;
    &_title {
        font-size: 17px;

        &_name{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-size: 35px;
            font-weight: 600;
            margin-bottom: 30px;
        }
        a {
            color: $base_very_light;
        }
        a:visited{
            color:$base_light
        }
    }

    &_form {
        display: flex;
        flex-direction: column;
        margin: 20px 0px 0px 0px;

        &_alert {
            margin: 7px 0px 7px 10px;
            font-size: 12px;
            color: $notify_red;
        }

        &_no_alert {
            height: 10px;
            width: 100%;
        }

        &_title{
            width: 100%;
        }

        &_content{
            width: 100%!important;
        }

        &_title_error {
            width: 100%;
            border-color: $notify_red;
        }

        &_content_error {
            width: 100%;
            border-color: $notify_red;
        }
}

    &_button_box{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 20px 0px 0px 0px;
        button {
            background-color: $base_medium;
            border-radius: 5px;
            text-align: center;
            cursor: pointer;
            transition-duration: 0.1s;
            outline: none;
            border: 1px solid transparent;
            border-radius: 20px;
            padding: 12px 22px 12px 22px;
            color: white;
        }

        button:active {
            background-color: $base_dark;
            transition-duration: 0.1s;
        }

        button:hover {
            background-color: $base_very_light;
            color: $white;
            transition-duration: 0.1s;
        }
    }
    &_info_box {
        margin: 50px 0px 0px 0px;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        a {
            color: $base_light;
        }
        a:visited {
            color: $base_light
        }
    }
}