@import '../utils.scss';

.profileSide {
    width: 100%;
    height: 100%;

    &_content {
        padding: 55px 55px 55px 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        color: $base_dark;

        @include breakpoint(1200px) {
            justify-content: center;
            padding: 0px 55px 55px 0px;
        }

        .title {
            font-size: 35px;
            display: flex;
            justify-content: flex-start;
            margin: 30px;
            
        }

        .optionsBox {

            display: flex;
            flex-direction: column;
            background-color: white;
            padding: 20px;
            width: 100%;
            justify-content: space-evenly;
            align-items: center;
            border: 2px solid $gray;
            border-radius: 20px;
            flex-wrap: wrap;

            @include breakpoint(1200px) {
                flex-direction: column;
                align-items: center;
            }

            &_info {
                display: flex;
                flex-direction: column;
                width:80%;
                padding-top: 25px;
                background-color: transparent;
                margin: 10px;
                align-self: center;
                flex-wrap: nowrap;

                .text {
                    width: 100%;
                    background-color: transparent;
                    font-size: 17px;
                    margin-bottom: 50px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;

                    &_title {
                        width:100%;
                        font-weight: bold;
                        background-color: $white;
                        margin-bottom: 20px;
                    }

                    &_value {
                        font-size: 18px;
                        width:100%;
                        display: flex;
                        background-color: transparent;
                        justify-content: flex-start;
                        color: #00000099;
                    }

                    input {
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        border-width: 1px;
                        padding: 10px;
                        font-size: 16px;
                    }

                    input:focus{
                        outline: none;
                        border-color: $base_very_light;
                        transition-duration: 0.1s;
                        padding: 10px;
                    }
                    .errorInput {
                        border-color: $notify_red;
                    }
                    .errorInput::placeholder {
                        color: $notify_red;
                    }
                    .errorInput:focus::placeholder {
                        color: #00000077;
                    }

                }
            }

            &_buttons {

                width: 80%;
                height: 100px;
                display: flex;
                flex-direction: row;
                gap: 20px;

                .EditButton{
                    background-color: $base_medium;
                    color: $white;
                    border-width: 0px;
                }

                button {
                    width: 50%;
                    height: 50px;
                    background-color: $white;
                    border-radius: 5px;
                    margin-bottom: 15px;
                    text-align: center;
                    cursor: pointer;
                    transition-duration: 0.1s;
                    outline: none;
                    border-width: 2px;
                    border-style: solid;
                    border-color: $gray_medium;
                    color: $base_dark;
                }

                button:active {
                    background-color: $base_medium;
                    transition-duration: 0.1s;
                }

                button:hover {
                    background-color: $base_very_light;
                    color: $white;
                    transition-duration: 0.2s;
                    border-style: none;
                }
            }
        }
    }
}
