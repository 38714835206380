.privacyContainer {
    padding: 0 10% 0 10%;

    .header {
        display: flex;
        align-items: center;
        margin: 40px 0 60px 0;
    }

    h1 {
        text-align: center;
        margin-left: 5%;
    }

    .backButton {
        font-size: 2.5em;
    }
}