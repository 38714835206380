@import "../utils.scss";

.container {
    &_cards {
        display: flex;
        width: 100%;
        gap: 10px;
        margin-bottom: 20px;
        justify-content: flex-start;
        flex-direction: column;

        @include breakpoint(1500px) {
            flex-wrap: wrap;
        }

        &_small {
            width: 100%;
            min-height: 75px;
            border: 2px solid $gray;
            border-radius: 20px;
            justify-content: center;
            align-items: center;
        }

        &_large {
            width: 100%;
            border: 2px solid $gray;
            border-radius: 20px;
        }
    }
}