@import '../utils.scss';

.error {
    display: flex;
    width: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;

    @include breakpoint(1100px) {
        flex-direction: column;
        align-items: center;
        margin-top: 150px;
    }

    &_leftBox {
        flex-basis: calc(40% - 15%);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 5% 0 10%;

        @include breakpoint(1100px) {
            padding: 0;
        }

        .photo {
            width: 80%;

            @include breakpoint(1100px) {
                width: 60%;
            }
        }

        .abosulete {
            position: absolute;
            margin-top: 80px;
        }
    }

    &_rightBox {
        flex-basis: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 50px;
        z-index: 10;

        @include breakpoint(1100px) {
            flex-direction: column;
            align-items: center;
        }

        .titleError {
            font-size: 150px;
            line-height: 150px;
            font-weight: 800;
        }

        .subtitleError {
            font-size: 35px;
            font-weight: 800;
        }

        .textError {
            margin-top: 40px;
            font-size: 20px;
        }

        .buttonBox {
            display: flex;
            flex-direction: row;
            gap: 15px;
            margin-top: 20px;

            .clear {
                background-color: $white;
                color: $black;
                border: 1px solid $dark_gray;

                &:hover {
                    color: $white;
                    border: 1px solid $base_light;
                }
            }

            .blue {
                background-color: $base_dark;
                color: $white;
            }

            button {
                display: flex;
                border-radius: 25px;
                cursor: pointer;
                transition-duration: 0.2s;
                outline: none;
                border: none;
                padding: 12px 25px 12px 25px;
            }

            button:hover {
                transition-duration: 0.2s;
                background-color: $base_light;
            }
        }
    }

    // animation below

    .drip, .drop, .wave {
        margin: 175px auto;
        max-width: 80vw;
    }

    .drop {
        position: relative;
        width: 20px;
        height: 20px;
        top: -30px;
        margin: 0 auto;
        background: $base_medium;
        -moz-border-radius: 20px;
        -webkit-border-radius: 20px;
        border-radius: 20px;
        -moz-animation-name: drip;
        -webkit-animation-name: drip;
        animation-name: drip;
        -moz-animation-timing-function: cubic-bezier(1,0,.91,.19);
        -webkit-animation-timing-function: cubic-bezier(1,0,.91,.19);
        animation-timing-function: cubic-bezier(1,0,.91,.19);
        -moz-animation-duration: 2s;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -moz-animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
      }

    .drop:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 30px solid $base_medium;
        top: -22px;
    }

    .wave {
        position: relative;
        opacity: 0;
        top: 0;
        width: 2px;
        height: 1px;
        border: $base_very_light 7px solid;
        -moz-border-radius: 300px / 150px;
        -webkit-border-radius: 300px / 150px;
        border-radius: 300px / 150px;
        -moz-animation-name: ripple;
        -webkit-animation-name: ripple;
        animation-name: ripple;
        -moz-animation-delay: 2s;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
        -moz-animation-duration: 2s;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -moz-animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        z-index: 0;
    }

    .wave:after {
        content: "";
        position: absolute;
        opacity: 0;
        top: -5px;
        left: -5px;
        width: 2px;
        height: 1px;
        border: $base_very_light 5px solid;
        -moz-border-radius: 300px / 150px;
        -webkit-border-radius: 300px / 150px;
        border-radius: 300px / 150px;
        -moz-animation-name: ripple-2;
        -webkit-animation-name: ripple-2;
        animation-name: ripple-2;
        -moz-animation-duration: 2s;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -moz-animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        max-width: 80vw;
    }

    @keyframes ripple {
        from {
            opacity: 1;
        }
        to {
            width: 600px;
            height: 300px;
            border-width: 1px;
            top: -100px;
            opacity: 0;
        }
    }

    @keyframes ripple-2 {
        0% {
        opacity: 1;
        }
        50% {
        opacity: 0;
        }
        100% {
        width: 200px;
        height: 100px;
        border-width: 1px;
        top: 100px;
        left: 200px;
        }
    }

    @keyframes drip {
        0% {
            opacity: 0;
        }
        30% {
            opacity: 1;
        }
        100% {
            top: 190px;
        }
    }
}