@import "../utils.scss";

.main {
    &_background {
        background: $background_color;
        background: $background_gradient;
        background-size: stretch;
    }

    &_login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;

        .logo {
            height: 10vh;
            padding-top: 100px;
            display: flex;
            justify-content: center;

            img {
                height: 100px;
            }

            &_image {
                width: 200px;
                height: 100px;
                border: 1px solid $white;
            }

            @include breakpoint(600px) {
                display: flex;
                justify-content: center;
            }
        }

        .animationContainer {
            display: flex;
            flex-direction: row;
            width: 300%;
            justify-content: space-around;
            transform: translateX(-100vw);

            .registration_container {
                height: 60vh;
                display: flex;
                justify-content: space-around;
                align-items: center;

                .button {
                    font-size: 17px;
                    font-weight: 600;
                    background-color: rgba(255, 255, 255, 0);
                    border: none;
                    cursor: pointer;
                    margin: 25px 0 0 10px;
                    display: inline-flex;
                    gap: 10px;
                    -webkit-tap-highlight-color: rgba(255,255,255,0);
                }
            }

            .login_container {
                height: 60vh;
                display: flex;
                justify-content: space-around;
                align-items: center;

                .button {
                    font-size: 15px;
                    background-color: rgba(255, 255, 255, 0);
                    border: none;
                    text-align: center;
                    text-decoration: none;
                    cursor: pointer;
                    padding: 10px 5px 10px 5px;
                    margin: 5px 0 5px 0;
                    border-radius: 10px;
                    -webkit-tap-highlight-color: rgba(255,255,255,0);

                    &_register {
                        margin-top: 20px;
                    }
                }

                @include breakpoint(600px) {
                    width: 100% !important;
                }
            }

            .forgot_container {
                height: 60vh;
                display: flex;
                justify-content: space-around;
                align-items: center;

                .button {
                    font-size: 17px;
                    font-weight: 600;
                    background-color: rgba(255, 255, 255, 0);
                    border: none;
                    cursor: pointer;
                    margin: 25px 0 0 10px;
                    display: inline-flex;
                    gap: 10px;
                    -webkit-tap-highlight-color: rgba(255,255,255,0);
                }

                @include breakpoint(600px) {
                    width: 100% !important;
                }
            }

            .content {
                margin: 10px;
                border-radius: 10px;
                padding: 15px;
                background-color: $glass;
                box-shadow: $shadow;
                width: 500px;
            }

            .headline {
                margin: 5px 0px 5px 20px;
                font-size: 20px;
                font-weight: 600;
            }

            .input_container {
                display: flex;
                flex-direction: column;

                &#buttons {
                    margin-top: 20px;
                }

                .error_box{
                    height: 16px;
                    padding: 5px;
                    border-radius: 5px;

                    span {
                        margin-left: 5px;
                        color: $notify_red;
                        font-size: 15px;
                    }
                }

                .row {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    gap: 20px;

                    .buttonComponent {
                        background-color: $gray_medium;
                        color: #222;
                    }

                    span {
                        flex-basis: 50%;

                        .buttonComponent {
                            width: 100%;
                        }
                    }
                }
            }
        }

        @keyframes left_to_center {
            from {
                transform: translateX(0vw);
            }
            to {
                transform: translateX(-100vw);
            }
        }

        @keyframes right_to_center {
            from {
                transform: translateX(-200vw);
            }
            to {
                transform: translateX(-100vw);
            }
        }

        @keyframes center_to_left {
            to {
                transform: translateX(0vw);
            }
        }

        @keyframes center_to_right {
            to {
                transform: translateX(-200vw);
            }
        }

        .slide_left_to_center {
            animation: left_to_center 0.7s forwards;
            -webkit-animation: left_to_center 0.7s forwards;
        }

        .slide_right_to_center {
            animation: right_to_center 0.7s forwards;
            -webkit-animation: right_to_center 0.7s forwards;
        }

        .slide_center_to_left {
            animation: center_to_left 0.7s forwards;
            -webkit-animation: center_to_left 0.7s forwards;
        }

        .slide_center_to_right {
            animation: center_to_right 0.7s forwards;
            -webkit-animation: center_to_right 0.7s forwards;
        }
    }
}
