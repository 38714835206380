@import '../utils.scss';

.faq_header {
    display: flex;
    align-items: center;
    margin-bottom:60px;

    h1 {
        text-align: center;
        margin-left: 5%;
    }

    .backButton {
        font-size: 2.5em;
    }
}

.faq{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;

    &_section{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 80%;

        &_title{
            display: flex;
            text-align: center;
            justify-content: center;
            flex-direction: row;
            font-size: 25px;
        }

        &_list{
            width: 100%;
            padding: 0px;

            li{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: baseline;
                gap: 10px;
                border: 2px solid $dark_gray;
                border-radius: 15px;
                font-size: 20px;
                transition-duration: 0.3s;
                overflow: hidden;
            }

            .question_title {
                padding: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                background-color: transparent;
                width: 100%;
                border: 0px;
                cursor: pointer;
                font-size: 0.9em;

                .icon {
                    padding: 5px;
                    cursor: pointer;
                }

                span{
                    padding: 5px;
                }
            }

            .question_title:hover {
                background-color: none!important;
            }

            .hidden {
                min-height: 0px;
                max-height: 0px;
                transition-duration: 0.5s;
            }

            .showed {
                min-height: 200px;
                max-height: 400px;
                transition-duration: 0.5s;
            }

            .expanded {
                display: flex;
                justify-content: flex-start;
                transition-duration: 0.5s;
                color: $dark_gray;
                font-size: 18px;
                overflow: hidden;
                padding: 0px 10px 0px 10px;
            }
        }
    }
}