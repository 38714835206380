.regulationsContainer {

    padding: 0 10% 0 10%;

    .header {
        display: flex;
        align-items: center;
        margin: 40px 0 60px 0;
    }

    h1 {
        text-align: center;
        margin-left: 5%;
    }

    p {
        margin: 50px 0 50px 0;
    }

    .backButton {
        font-size: 2.5em;
    }

    ol { counter-reset: item }
    li{
        margin-top: 15px;
        display: block 
    }
    li:before { 
        content: counters(item, ".") " "; 
        counter-increment: item;
        font-weight: bold; 
    }

}