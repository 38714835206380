@import '../utils.scss';

.topBar {
    width: 100%;
    height: $topbar_height;
    background-color: $gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 10;

    &_menu_mobile {
        display: none;
        margin-left: 20px;

        @include breakpoint(600px) {
            display: block;
            margin-left: 10px
        }

        button {
            outline: none;
            border: none;
            background-color: rgba(255, 0, 0, 0);
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        }
    }

    &_logo {
        height: 70%;
        display: flex;
        align-items: center;

        a {
            display: flex;
            max-height: 100%;
        }

        img {
            object-fit: contain;
            object-position: left;
            width: 300px;
            margin-left: 1rem;

            @include breakpoint(600px) {
                object-position: center;
                max-height: 60%;
                max-width: 100%;
            }
        }
    }

    &_account {
        display: flex;
        align-items: center;

        .avatar {
            background-color: $base_medium;
            color: $white;
        }

        span {
            margin-left: 10px;
            margin-right: 10px;
        }

        .avatar_tittle{
            color: $base_dark;
        }

        .newnotification{
            position: absolute;
            top: 1.8rem;
            right: 0.1rem;
            margin: 0;
            width: 1rem;
            height: 1rem;
            line-height: 1rem;
            border-radius: 1rem;
            background-color: red;
            color: $white;
            font-size: 0.8rem;
        }

        button {
            position: relative;
            outline: none;
            border: none;
            background-color: rgba(255, 255, 255, 0);
            cursor: pointer;
            margin-right: 20px;
        }

        .buttonNotification{
            color: $base_dark;
        }

        @include breakpoint(600px) {
            display: none;
        }

        &_mobile {
            display: none;
            margin-right: 0px;

            @include breakpoint(600px) {
                display: block;
            }

            button {
                outline: none;
                border: none;
                background-color: rgba(255, 255, 255, 0);
                margin-right: 5px;
            }
        }
    }

    &_notification {
        width: 420px;
        display: flex;
        flex-direction: column;
        margin: 0 1rem 1rem 1rem;
        padding: 0.7rem;
        border: none;
        border-radius: 1.5rem;
        background-color: $gray;
        color: $base_dark;

        &_tittle {
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;
            align-items: center;
            text-align: start;
        }

        &_tittle-icon {
            align-self: center;
            margin-right: 10px;
        }

        &_date {
            font-size: 0.7rem;
            align-self: flex-end;
        }

        &_noresult {
            text-align: center;
            margin: 5px;
        }

        &_dilog {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            span{
                color: $base_dark;
                text-align: end;
            }
        }

        &_text{
            color: $base_dark;
            margin-bottom: 40px;
        }

        p {
            margin-top: 0px;
            font-size: 0.9rem;
        }

        &:hover{
            cursor: pointer;
            background-color: $gray_medium;
        }

    }
}

.noHover{
    pointer-events: none;
}
