@import "../utils.scss";

.dialog {
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_image_container {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 400px;

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }

        &_empty {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 70%;
            height: 20vh;
            margin: auto;
            border-radius: 15px;
            border: 2px dashed $dark_gray;

            span {
                color: $base_dark;
            }
        }
    }

    .span_box{
        width: 100%;
        display: grid;
        grid-template-columns: max-content auto;
        column-gap: 20px;
        padding: 15px 0px;
        align-items: center;

        .pill {
            font-size: 14px;
            width: fit-content;
            padding: 12px 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            &_green {
                background-color: $notify_green;
                color: $white;
            }

            &_red {
                background-color: $notify_red;
                color: $white;
            }

            &_blue {
                background-color: $base_light;
                color: $white;
            }
        }
    }

    span {
        color: $base_dark;
        padding-top: 10px;
    }

    .button_box{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 15px;
        align-items: center;

        button {
            padding: 10px 20px;
            background-color: $white;
            border-radius: 10px;
            text-align: center;
            cursor: pointer;
            transition-duration: 0.1s;
            outline: none;
            border: 2px solid $gray_medium;
            color: $base_dark;
        }

        .reject_button {
            background-color: $notify_red;
            color: white;
            border-color: $notify_red;

            &[disabled], &:disabled {
                background-color: $dark_gray;
                border-color: $dark_gray;
            }

            &:hover {
                background-color: $base_dark;
                border-color: $base_dark;

                &[disabled], &:disabled {
                    background-color: $dark_gray;
                    border-color: $dark_gray;
                    cursor: not-allowed;
                }
            }
        }

        .accept_button {
            background-color: $base_light;
            color: white;
            border-color: $base_light;

            &[disabled], &:disabled {
                background-color: $dark_gray;
                border-color: $dark_gray;
            }

            &:hover {
                background-color: $base_dark;
                border-color: $base_dark;

                &[disabled], &:disabled {
                    background-color: $dark_gray;
                    border-color: $dark_gray;
                    cursor: not-allowed;
                }
            }
        }

        button:hover {
            background-color: $base_very_light;
            color: $white;
            transition-duration: 0.1s;
            border-color: $base_very_light;
        }
    }

}