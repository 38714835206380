@import '../utils.scss';

.progress_container { 
    height: 20px;
    width: 100%;
    border-radius: 50px;

    display: flex;

    .filler{
        height: 100%;
        background-color: #21b49b;
        text-align: right;
        overflow: hidden;
        display: flex;
        align-items: center;

        &:first-of-type {
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }

        &:last-of-type {
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }
    }

    .label {
        display: flex;
        color: white;
        padding-left: 5px;
        font-size: 12px;
        justify-content: flex-start;
    }

    .blue {
        background-color: $base_light;
    }

    .green {
        background-color: $notify_green;
    }

    .red {
        background-color: $notify_red;
    }
}