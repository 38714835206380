@import '../utils.scss';

.pagination_box{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 15px;
    margin-bottom: 20px;

    .pagination_input {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        input {
            width: 100%;
            background-color: rgba(1, 1, 1, 0);
            outline: none;
            border: none;
        }

        input:focus {
            outline: none;
            border-color: $base_very_light;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            appearance: textfield;
            font-size: 12px;
            font-weight: 500;
        }

        .search_input {
            border: 1px solid $dark_gray;
            display: flex;
            align-items: center;
            border-radius: 5px;
            padding: 0px 5px 0px 5px;
            width: 120px;
            align-items: center;

            .clearButton {
                padding: 5px;
            }

            .clearButton:hover {
                background-color: $base_very_light;
                color: $white;
            }

            @include breakpoint(800px) {
                width: 100%;
            }
        }
    }
}