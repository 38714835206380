@import './utils.scss';

body {
    margin: 0;
    font-family: 'Gilroy', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a, a:active, a:visited {
        text-decoration: none;
        color: $black;
    }

    .main {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &_content {
            margin-top: 80px;
            padding: 15px 15px 0 calc($menu_width + 25px);

            .title {
                width: 100%;
                margin: 10px 10px 10px 0px;
                display: flex;
                flex-direction: column;
                color: $base_dark;

                &_name {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 35px;
                    margin-left: 15px;
                    font-weight: 600;
                    color: $base_dark;

                    button {
                        height: 40px;
                        background-color: $white;
                        margin-left: 15px;
                        text-align: center;
                        cursor: pointer;
                        transition-duration: 0.1s;
                        outline: none;
                        border: 2px solid $gray_medium;
                        border-radius: 20px;
                        padding: 0 15px 0 15px;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-right: 30px;
                    }

                    .exit_button:hover{
                        background-color: $base_light;
                        border: 2px solid $base_light;
                        color: $white;
                    }

                    #addInvoice {
                        background-color: $base_medium;
                        color: $white;
                    }

                    #addInvoice:hover{
                        background-color: $base_light;
                    }
                }

                &_locales {
                    margin: 15px;
                    width: 50%;
                    display: flex;
                    justify-content: flex-start;
                    gap: 20px;
                    flex-wrap: wrap;
                    color: #444444BB;
                }

                &_route {
                    margin: 10px 0 0 15px;
                    display: flex;
                    gap: 5px;

                    * {
                        color: $dark_gray;
                        font-size: 15px;
                    }
                }
            }

                .notify_title{
                    @extend .title;
                    flex-direction: row;
                    gap: 10px;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    &_form_steps{
                        display: flex;
                        flex-direction: row;
                        justify-self: center;
                        width: 40%;
                        font-size: 15px;
                        font-weight: 600;
                        align-items: baseline;
                        min-width: 250px;

                        .stepContainer {
                            display: flex;
                            align-items: baseline;
                            flex: 1;

                            hr {
                                border-top: 1px solid black;
                                width: 100%;
                                height: 0px;
                                transform: translateY(-0.25em);
                            }
                        }

                        .circle_box{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 49px;

                            span {
                                text-align: center;
                                font-weight: 500;
                                font-size: 13px;
                                color: $dark_gray;
                            }

                            .circle {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 45px;
                                width: 45px;
                                border: 2px solid $dark_gray;
                                background-color: $gray;
                                border-radius: 50%;
                                margin-bottom: 5px;
                            }

                            .highlightedCircle {
                                background-color: $base_medium !important;
                                color: white;
                                border-color: $base_medium !important;
                            }

                            .doneCircle{
                                background-color: $base_very_light !important;
                                color: white;
                                border-color: $base_very_light!important;
                            }
                        }
                    }



                    button {
                        height: 40px;
                        background-color: $white;
                        margin-left: 15px;
                        text-align: center;
                        cursor: pointer;
                        transition-duration: 0.1s;
                        outline: none;
                        border: 2px solid $gray_medium;
                        border-radius: 20px;
                        padding: 0 15px 0 15px;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-right: 30px;
                    }

                    .exit_button:hover{
                        background-color: $base_light;
                        color: $white;
                        border: 2px solid $base_light
                    }


                }

            .container {
                display: flex;
                flex-direction: column;
                width: 80%;
                margin: auto;
                transition-duration: 0.4s;

                @include breakpoint(1800px) {
                    width: 95%;
                    transition-duration: 0.4s;
                }

                &_mod {
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 30px;
                }
            }

            @include breakpoint-over(2100px) {
                width: 1920px;
                margin: 80px auto 0 auto;
                padding-left: 0px;
            }

            @include breakpoint(600px) {
                padding: 15px;
                justify-content: center;
                align-items: center;
            }

        }
    }
}
