@import '../utils.scss';

.filters_container {
    width: 80%;
    margin: 10px auto 0px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    transition-duration: 0.3s;

    @include breakpoint(1800px) {
        transition-duration: 0.3s;
        width: 95%;
    }
    @include breakpoint(820px) {
        flex-direction: row;
    }


    #actions {
        margin-left: auto;
        border-right: 1px solid $gray_medium;
        padding-right: 10px;
        margin-right: 5px;
        display: flex;

        div {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            align-items: center;
        }

        .option {
            border-radius: 15px;
        }
    }

    .row_title {
        flex-basis: 10%;
        min-width: 110px;

        @include breakpoint(800px) {
            flex-basis: unset;
            min-width: unset;
            margin-right: 20px;
        }
    }

    .active_filter {
        font-size: 0.8em;
        padding: 3px 3px 3px 9px;
        background-color: $base_medium;
        border-radius: 50px;
        color: $white;

        display: flex;
        align-items: center;
        gap: 5px;

        button {
            padding: 3px !important;
            border-radius: 20px;

            &:hover {
                background-color: $notify_red;
            }
        }
    }

    .filters_row {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        height: 45px;

        @include breakpoint(800px) {
            flex-wrap: wrap;
        }
        @include breakpoint(820px) {
            flex-direction: row;
        }

        .choose_row {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            .option {
                border-radius: 15px;
            }
        }

        .search_row {
            display: flex;
            gap: 15px;
            align-items: center;
        }

        .search_input {
            border: 1px solid $gray_medium;
            min-width: 170px;
            display: flex;
            align-items: center;
            padding: 5px;

            border-radius: 20px;

            .clearButton:hover {
                background-color: $base_very_light;
                color: $white;
            }

            @include breakpoint(800px) {
                width: 100%;
            }
        }

        input[type=text], input[type=text]:focus {
            padding: 5px;
            width: 100%;
            background-color: rgba(1,1,1,0);
            outline: none;
            border: none;
        }
    }
}