@import '../utils.scss';

.table {
    width: 100%;
    border: none !important;

    &_title {
        font-weight: 600;
        color: $base_dark;
    }

    &_title_col {
        color: $base_dark;
    }

    .header {
        background-color: transparent;
        border: none;
        border-bottom: 2px solid $gray;
        color: $black !important;
        padding-right: 0;

        &_color {
            background-color: $base_dark;
            color: $white;
            padding-right: 0;
            border-radius: 15px !important;
        }
    }

    .not_verified {
        background-color: $not_verified !important;

        & > * > * {
            font-weight: 500;
        }

        &:hover {
            background-color: $not_verified_hover !important;
        }

        .button {
            background-color: $white;

            &:hover {
                background-color: $base_medium !important;
            }
        }
    }

    .row {
        background-color: $gray;
        border-radius: 15px;
        margin-top: 10px;
        margin-bottom: 5px;
        transition-duration: 0.2s;

        &:hover {
            background-color: $gray_medium;
            transition-duration: 0.2s;
        }

        .button_container {
            display: flex;
            gap: 10px;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        .gap {
            background-color: white;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 1px;
            padding: 0px 1px;
        }

        .clients_notification {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
        }

        .canal_icon_name {
            display: flex;
            align-items: center;
            gap: 5px;

            @include breakpoint(1000px) {
                align-items: unset;
                flex-direction: column;
            }

            svg {
                color: $base_dark;
            }

            span {
                display: flex;
                align-items: center;
                gap: 5px;

                span {
                    border-radius: 50px;
                    background-color: $white;
                    padding: 5px;
                }
            }
        }

        .alone_row {
            display: flex;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 50px;
            padding: 0px;

            .clickable {
                width: 50px;
                min-height: 63px;
                border: none;
                background-color: rgba(0, 0, 0, 0);
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .icon_white {
                    color: $white;
                }
            }

            .clickable:hover {
                color: white;
            }
        }

        .alone_row:hover {
            background-color: $base_medium;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }

        .user_state_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            .icon {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                background-color: #fff;
                padding: 7px;
            }

            .verfied_user {
                color: $notify_green !important;
                font-size: 18px;
            }

            .rejected_user {
                color: $notify_red !important;
                font-size: 18px;
            }

            .new_user {
                color: $base_light !important;
                font-size: 18px;
            }

            .button {
                display: flex;
                gap: 5px;
                background-color: $white !important;
                color: $black !important;
                padding: 10px;
                border-radius: 10px;
                justify-content: center;
                align-items: center;
                border: none;
                cursor: pointer;
                color: $base_dark;
                transition-duration: 0.2s;

                &:hover {
                    transition-duration: 0.2s;
                    background-color: $base_light !important;
                    color: $white !important;

                    svg {
                        color: $white !important;
                    }
                }
            }
        }
        .container_expanded {
            justify-content: center;
        }

        .button {
            display: flex;
            gap: 5px;
            background-color: $gray;
            padding: 5px;
            border-radius: 10px;
            justify-content: center;
            align-items: center;
            border: none;
            cursor: pointer;
            color: $base_dark;
            transition-duration: 0.1s;

            &:hover {
                transition-duration: 0.1s;
                background-color: $base_dark;

                svg {
                    color: $white !important;
                }
            }
        }

        .centered {
            text-align: center;
        }

        .alert {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;

            &_circle {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $notify_red;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                color: $white;
                border: 2px solid $white;
            }
        }
    }

    .hidden {
        transition: 0.3s linear;
        width: 96% !important;
        max-height: 0;
    }

    .expanded {
        transition: 0.3s linear;
        transition-delay: 0.2s;
        width: 100%;
        max-height: 420px;

        @include breakpoint(1000px) {
            max-height: 700px;
        }
    }

    .loading {
        background-color: $base_very_light;
        width: 80%;
        height: 20px;
        border-radius: 10px;
    }

    .gradient {
        animation-duration: 4s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: linear-gradient(45deg, $gray 8%, $gray_medium 38%, $gray 54%);
        background-size: 1000px 25px;
    }

    @keyframes placeHolderShimmer {
        0% {
            background-position: -1000px 0;
        }
        100% {
            background-position: 1000px 0;
        }
    }

    .in_color > div > span {
        color: $white !important;
        transition-duration: 0.2s;

        .meter_type {
            color: $black !important;
        }

        .icon {
            color: $base_light;
        }

        a {
            color: #fff !important;
        }
    }

    .in_color,
    .in_color:hover {
        background-color: $base_medium !important;
        border-radius: 15px 15px 0 0;
        transition-duration: 0.2s;
    }

    .selected {
        background-color: $base_very_light !important;
        color: $black;

        & > div > span {
            color: $black;
            transition-duration: 0.1s;
            font-weight: 500;
        }
    }

    .more_info {
        background-color: $base_light;
        border-radius: 0 0 10px 10px;
        width: 100%;
        margin: auto;
        margin-top: -6px;
        color: $white;
        overflow: hidden;
        margin-bottom: 10px;

        &_content {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            gap: 20px;

            #title {
                text-align: left;
                font-size: 16px;
                font-weight: 600;
                padding-bottom: 5px;
                margin-bottom: 10px;
                border-bottom: 1px solid $gray_medium;
            }

            @include breakpoint(1000px) {
                gap: 10px;
                flex-direction: column;
            }

            &_chart {
                width: 50%;
                border-radius: 20px;
                padding: auto;

                @include breakpoint(1000px) {
                    display: none;
                }
            }

            &_no_chart {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                width: 50%;

                .chart {
                    position: relative;
                    bottom: 95px;
                }
                .info_box {
                    position: relative;
                    display: flex;
                    align-items: center;
                    min-height: 20%;
                    max-width: 65%;
                    border: 2px dashed $gray;
                    background-color: $base_light;
                    z-index: 1;
                    top: 150px;
                    text-align: center;
                    border-radius: 20px;
                    transition-duration: 0.2s;
                    padding: 5px;

                    @include breakpoint(1150px) {
                        transition-duration: 0.2s;
                        top: 0px;
                    }

                    span {
                        font-size: 17px;
                    }
                }

                @include breakpoint(1000px) {
                    display: none;
                }
            }

            .column {
                display: flex;
                flex-direction: column;
                min-width: 150px;
                gap: 10px;

                @include breakpoint(800px) {
                    gap: 10px;
                    flex-direction: column;
                }

                &_right {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;

                    a {
                        width: 100%;
                    }
                }

                &_left {
                    flex-basis: 80%;
                }

                &_fixed {
                    width: 100%;
                }

                .data_grid {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    column-gap: 20px;
                    row-gap: 5px;
                    align-items: center;

                    #spacer_row {
                        grid-area: auto / 1 / auto / 3;
                        margin: 20px 0 10px 0;
                    }
                }

                .button_container {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                }
            }

            p {
                margin: 0;
                line-height: 22px;
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                button {
                    color: $white;
                    background-color: $base_medium;
                    &:hover {
                        background-color: $base_dark;
                        &[disabled] {
                            background-color: $base_medium;
                            cursor: auto;
                        }
                    }
                }
                a,
                a:active,
                a:visited {
                    text-decoration: none;
                    color: $white;
                }
            }

            .inLine {
                color: $white;
                display: flex;
                align-items: center;
                gap: 10px;
            }

            .button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                background-color: $gray;
                border: none;
                color: $black;
                cursor: pointer;
                padding: 12px;
                border-radius: 10px;
                transition-duration: 0.2s;

                &:hover {
                    background-color: $base_dark;
                    color: $white;
                    transition-duration: 0.2s;

                    svg {
                        color: $white !important;
                    }
                }
            }
        }
    }

    .invoiceButton {
        color: $black !important;
        padding: 8px !important;

        &:hover {
            color: $white !important;
        }

        @include breakpoint(800px) {
            padding: 10px 20px 10px 20px;

            span {
                display: none;
            }
        }
    }

    span > span {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
            border-radius: 50px;
            background-color: $white;
            padding: 5px;
        }

        .icon {
            color: $base_light;

            &_blue {
                color: $base_light;
            }
            &_red {
                color: $notify_red;
            }
            &_green {
                color: $notify_green;
            }
            &_yellow {
                color: $notify_yellow;
            }
        }
    }
}

.renderImageButtonDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10;
}

.cursor-pointer {
    cursor: pointer;
}
