@import '../utils.scss';

.menuComponent {
    width: $menu_width;
    height: calc(100vh - $topbar_height);
    background-color: $gray;
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: fixed;
    margin-top: $topbar_height;
    z-index: 1;
    overflow: auto;

    @include breakpoint(600px) {
        width: 0;
        padding-left: 0;
    }

    a {
        border-radius: 30px;

        @include breakpoint(600px){
            margin-left: 15px;
            margin-right: 15px;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        }

        &:hover button {
            background-color: $base_very_light;
            color: $white;
        }

        &:not(:first-of-type) {
            margin-top: calc(2 * $spacing);
        }
    }

    .element {
        display: flex;
        align-items: center;
        flex-direction: row;

        button {
            width: 40px;
            height: 40px;
            outline: none;
            border: none;
            border-radius: 30px;
            background-color: $white;
            color: $base_dark;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition-duration: 0.2s;
            position: relative;
        }

        .badge {
            background-color: $base_dark;
            color: $white;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            position: absolute;
            top: -6px;
            right: -6px;
        }

        .active {
            background-color: $base_medium;
            color: $white;
        }

        span {
            margin-left: $spacing;
            text-align: center;
            left: 90px;
            font-size: 13px;
            transition-duration: 0.2s;
        }

        @include breakpoint(600px) {
            display: none;
        }
    }
}
