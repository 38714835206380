@import "../utils.scss";

.home {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(1000px) {
        flex-direction: column-reverse;
    }

    hr {
        border: 0;
        height: 2px;
        background-image: linear-gradient(to right, $white, $base_dark, $white);
        margin-left: 10px;
        margin-right: 10px;
    }

    .statistic_container {
        display: flex;
        width: 100%;
        gap: 10px;
        margin-bottom: 20px;
        justify-content: flex-start;
        flex-direction: column;

        .card_box{
            flex-wrap: wrap;
            display: flex;
            gap: 10px;
        }
        .user_card_box{
            display: flex;
            width: 100%;
            flex-direction: row;
            gap: 20px;
        }
        .meters_and_measurements_box{
            display: flex;
            width: 100%;
            gap: 20px;
        }
        .title {
            display: flex;
            align-items: flex-start;
            font-size: 18px;
            font-weight: 600;
        }

        @include breakpoint(1000px) {
            width: 100%;
            flex-direction: row;
        }
    }

    .card_title {
        padding: 10px 25px 0px 25px ;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;

        &_large {
            font-size: 18px;
            padding: 20px 25px 10px 25px ;
        }

        a,
        a:visited,
        a:active {
            color: $base_dark;
            transition-duration: 0.3s;
        }

        a:hover {
            color: $base_light;
            transition-duration: 0.3s;
        }
    }

    .card_content {
        margin: 10px 20px 10px 20px ;
        display: flex;
        flex-direction: column;
        p {
            display: flex;
            font-size: 14px;
        }

        .dot {
            margin-right: 10px;
        }

        .color_green {
            color: $notify_green;
        }

        .color_red {
            color: $notify_red;
        }

        .color_blue {
            color: $base_light;
        }
    }

    .table_footer {
        margin: 15px 25px 15px 0;
        display: flex;
        justify-content: flex-end;

        a {
            display: flex;
            align-items: center;
            color: $dark_gray;
            transition-duration: 0.2s;
            gap: 10px;
            color: $black;
        }

        a:hover {
            color: $base_medium;
            transition-duration: 0.2s;
        }
    }
}
