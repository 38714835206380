$base_very_light: #80A4FF;
$base_light: #5274CC;
$base_medium: #2e4c99;
$base_dark: #142c66;
$base_very_dark: #031033;

$notify_green: #21b49b;
$notify_red: #F84F31;
$notify_yellow: #FFA500;

$not_verified: #D7E4FF;
$not_verified_hover:#A1BFFF;

$verified:#14b8e7;

$gray: #F4F4F8;
$gray_medium: #EAEAEA;
$dark_gray: #838383;
$gray_ver_dark:#999999;

$spacing: 10px;
$radius: 15px;

$white: #FFF;
$black: #000;

$background_gradient: linear-gradient(45deg,
        rgba(48, 41, 168, 1) 0%,
        rgba(27, 65, 158, 1) 28%,
        rgba(15, 153, 181, 1) 100%);
$background_color: rgb(48, 41, 168);

$shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2);
$glass: rgba(255, 255, 255, 0.6);

$topbar_height: 80px;
$menu_width: 150px;

@mixin breakpoint($breakpoint ) {
    @if type_of($breakpoint )==number and unit($breakpoint )==px or unit($breakpoint )==em or unit($breakpoint )==rem {
        @media (max-width: $breakpoint ) {
            @content;
        }
    }

    @else {
        @warn "Nie można ustalić breakpoint!";
    }
}

@mixin breakpoint-over($breakpoint ) {
    @if type_of($breakpoint )==number and unit($breakpoint )==px or unit($breakpoint )==em or unit($breakpoint )==rem {
        @media (min-width: $breakpoint ) {
            @content;
        }
    }

    @else {
        @warn "Nie można ustalić breakpoint!";
    }
}
