@import "../utils.scss";

.dialog_box_container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;
    text-align: center;

    .title {
        margin-top: 1rem;
        font-size: 1rem;
    }

    .different_states_warning {
        &_container {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            gap: 10px;
        }

        &_icon {
            color: $notify_red;
            font-size: 16px;
            font-weight: 600;
        }

        &_text {
            margin-top: -10px;
            font-size: 14px;
        }
    }
}