@import '../utils.scss';

.home {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    &__left {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 20px;
    }

    &__right {
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    hr {
        height: 0px;
    }
}

.table_home_meter {
    border: 3px solid $gray;
    border-radius: 30px;
    padding: 20px;

    &__bar {
        display: flex;
        flex-direction: column;
        margin: 4rem;
        color: $base_dark;
        text-align: center;
    }
}

.table_home_measurement {
    width: 80%;
    margin: 0 auto;
    border: 3px solid $gray;
    border-radius: 30px;
    padding: 20px;
}
