@import "../utils.scss";


.dialogCustomFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;

    .button{
        cursor: pointer;
        padding: 10px 20px;
        border: 2px solid $base_medium;
        background-color: $base_medium;
        color: $white;
        border-radius: 10px;
        transition-duration: 0.1s;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

        &:hover {
            background-color: $base_dark;
            border-color: $base_dark;
            transition-duration: 0.1s;
        }
    }

    .rejectButton{
        cursor: pointer;
        padding: 10px 20px;
        border: 2px solid $notify_red;
        background-color: $notify_red;
        color: $white;
        border-radius: 10px;
        transition-duration: 0.1s;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

        &[disabled], &:disabled {
            background-color: $dark_gray;
            border-color: $dark_gray;
        }

        &:hover {
            background-color: $base_dark;
            transition-duration: 0.1s;
            border-color: $base_dark;

            &[disabled], &:disabled {
                background-color: $dark_gray;
                border-color: $dark_gray;
                cursor: not-allowed;
            }
        }
    }

    .cancelButton {
        cursor: pointer;
        padding: 10px 20px;
        border: none;
        background-color: transparent;
        color: $black;
        border-radius: 10px;
        transition-duration: 0.1s;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        border: 2px solid $gray_medium;

        &:hover {
            background-color: $base_light;
            color: $white;
            transition-duration: 0.2s;
            border-color: $base_light;
        }
    }

    .selectFileButton{
        width: 150px;
        border: none;
        text-align: center;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: transparent;
        color: $black;
        border-radius: 10px;
        margin-left: 5px;
        transition-duration: 0.1s;
        border-width: 2px;
        border-style: solid;
        border-color: transparent;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }
    .selectFileButton:hover {
        transition-duration: 0.2s;
        border-color: $gray_medium;
    }

}

.decline_box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 15px;
    min-width: 600px;
    transition-duration: 0.2s;

    @include breakpoint(650px) {
        transition-duration: 0.2s;
        min-width: 400px;
    }
}

.user_information_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    overflow-x: hidden;

    .picture_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        span {
            color: $base_dark;
            font-size: 13px;
        }

        .image_container {
            display: flex;
            justify-content: center;
            max-width: 100%;
            height: 430px;
            padding-bottom: 10px;
        }

    }

    .user_verification_column {
        display: flex;
        flex: 1;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 20px;

        span {
            color: $base_dark;
        }
    }

    .user_data_box {
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        column-gap: 10px;
        row-gap: 5px;

        .row {
            grid-area: auto / 1 / auto / 3;
            margin-top: 10px;
        }

        .pill {
            font-size: 14px;
            width: fit-content;
            padding: 12px 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            &_green {
                background-color: $notify_green;
                color: $white;
            }

            &_red {
                background-color: $notify_red;
                color: $white;
            }

            &_blue {
                background-color: $base_light;
                color: $white;
            }
        }
    }

    .data_box{
        display: flex;
        flex-direction: column;
        padding: 5px;
        border-radius: 15px;
        width: 100%;

        span {
            margin-bottom: 15px;
            align-self: flex-start;
            font-size: 19px;
        }

        label{
            padding: 5px 0px;
            margin-left: 15px;
            border-radius: 5px;
            font-size: 13px;
            color: $dark_gray;
        }

        input {
            border-top: none;
            border-left: none;
            border-right: none;
            border-width: 1px;
            font-size: 16px;
            margin-bottom: 15px;
            min-width: 300px;

            &:focus {
                outline: none;
                border-color: $base_very_light;
                transition-duration: 0.1s;
            }
        }

        .errorInput {
            border-color: $notify_red;

            &::placeholder {
                color: $notify_red;
                font-size: 14px;
            }

            &:focus::placeholder {
                color: #00000077;
            }
        }
    }
    .textArea{
        max-width: 100%;
        min-width: 100%;
    }
    .textInput{
        min-width: 100%;
    }
}

.dialog {
    &__row {
        display: flex;
        justify-content: space-evenly;
    }

    &__rowItem {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: center;
        gap: 10px;

        &__left {
            justify-content: flex-start;
        }
    }

    &__tab {
        margin-top: -10px;
        border: 1px solid #2e4c99;
        border-radius: 10px;
        padding: 15px;
    }

    &__disabled {
        cursor: no-drop;

        button {
            background-color: #F4F5F9;
            pointer-events: none;
        }
    }
}
