@import '../utils.scss';

.profile {
    &_content {

        margin-top: 80px;
        padding: 15px 15px 0 105px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include breakpoint(1200px) {
            flex-direction: column;
            align-items: center;
        }

        .profileBox {
            width: 60%;
            display: flex;
            flex-direction: column;
            background-color: transparent;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;

            @include breakpoint(1200px) {
                width: 95%;
            }


            &_info {
                margin-top: 50px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 50px;

                &_title {
                        font-size: 30px;
                        color: $base_dark;
                }

                &_avatar {
                    width: 150px;
                    height: 150px;
                    border-radius: 75px;
                    background-color: transparent;
                    text-align: center;
                    margin-bottom: 15px;

                    .avatar {
                        background-color: $base_medium;
                        color: $white;
                    }
                }
            }


            &_options {
                background-color: transparent;
                padding: 10px;
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                &_element{
                    width: 85%;
                    background-color: $white;
                    border-radius: 5px;
                    margin-bottom: 15px;
                    text-align: center;
                }

                button {
                    width: 85%;
                    height: 50px;
                    background-color: $white;
                    border-radius: 5px;
                    margin-bottom: 15px;
                    text-align: center;
                    cursor: pointer;
                    transition-duration: 0.1s;
                    outline: none;
                    border: none;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 30px;
                    font-size: 20px;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 15px;
                    color: $base_dark;
                }

                button:active {
                    background-color: $base_medium;
                    transition-duration: 0.1s;
                }

                button:hover {
                    background-color: $base_very_light;
                    color: $white;
                    transition-duration: 0.2s;
                }

                #logout:hover {
                    background-color: $notify_red;
                }
            }


        }

        .settingsBox{
            min-width: 65%;
            background-color: transparent;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 10px;

            @include breakpoint(1200px) {
                width: 95%;
            }
        }


        @include breakpoint-over(2100px) {
            width: 1920px;
            margin: 80px auto 0 auto;
            padding-left: 0px;
        }

        @include breakpoint(600px) {
            padding: 15px;
            justify-content: center;
            align-items: center;
        }

    }

}