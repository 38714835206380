@import '../utils.scss';

.contact{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 5rem;
    gap: 3rem;

    &__column-info{
        display: flex;
        flex-direction: column;
        span{
            margin-bottom: 0.5rem;
        }
    }

    &__adres{
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        &:hover{
            color: $base_medium;
        }
    }
}

.margin{
    &_b{
        margin-bottom: 0.5rem;
    }
    &_l{
        margin-left: 1rem;
        &:hover{
            color: $base_medium;
        }
    }
}